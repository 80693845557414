import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ForecastService {
  constructor(private http: HttpClient) {}
  getForecastData(data: number): Observable<any> {
    return this.http
      .post(`${environment.api_url}` + "/forecast", { days: data })
      .pipe(map((res) => res));
  }
}
