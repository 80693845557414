import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "src/app/layout/layout.component";
import { ChatbotComponent } from "./components/chatbot/chatbot.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ForecastComponent } from "./components/forecast/forecast.component";
import { HomeComponent } from "./components/home/home.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "home", component: HomeComponent },
      {
        path: "chatbot",
        component: ChatbotComponent,
      },
      {
        path: "forecast",
        component: ForecastComponent,
      },
      {
        path: "dashboard-analytics",
        component: DashboardComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
