<!-- start slider  -->
<div class="main-container">
  <div
    id="carouselExample"
    class="carousel slide w-100"
    data-bs-ride="carousel"
    data-bs-interval="3000"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide-to="0"
        class="active"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide-to="1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide-to="2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide-to="3"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide-to="4"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active img">
        <img
          class="slider-img"
          src="assets/images/banner2.png"
          alt="First slide"
        />
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
      <div class="carousel-item img">
        <img
          class="slider-img"
          src="assets/images/banner3.jpg"
          alt="Third slide"
        />
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
      <div class="carousel-item img">
        <img
          class="slider-img"
          src="assets/images/banner4.jpg"
          alt="Third slide"
        />
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
      <div class="carousel-item img">
        <img
          class="slider-img"
          src="assets/images/banner5.jpg"
          alt="Third slide"
        />
        <div class="carousel-caption d-none d-md-block">
          <h5></h5>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end slider -->

<!-- start main content  -->
<div class="container py-5">
  <div class="col-md-12">
    <div class="row col-md-12">
      <div class="card border-0 col-12 col-sm-4 bg-unset mt-sm-2">
        <div class="box-shadow rounded card-body">
          <h5 class="card-title text-center">COVID-19 Dashboards</h5>
          <div class="mt-4">
            <img
              class="w-100"
              src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/dashboards.png/:/cr=t:0.55%25,l:0%25,w:100%25,h:98.91%25/rs=w:388,h:291.72932330827064,cg:true"
              alt=""
              height="238"
            />
          </div>
          <div class="text-center mt-4">
            <button
              class="btn btn-outline-dark py-2 px-5 border-2 rounded-pill"
              routerLink="/covid-analytics/dashboard-analytics"
            >
              Explore More
            </button>
          </div>
        </div>
      </div>
      <div class="card border-0 col-12 col-sm-4 bg-unset mt-sm-2">
        <div class="box-shadow rounded card-body">
          <h5 class="card-title text-center">Forecast COVID-19</h5>
          <div class="mt-4">
            <img
              class="w-100"
              src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/forecast.png/:/cr=t:1.65%25,l:0%25,w:100%25,h:96.7%25/rs=w:388,h:291.72932330827064,cg:true"
              alt=""
              height="238"
            />
          </div>
          <div class="text-center mt-4">
            <button
              class="btn btn-outline-dark py-2 px-5 border-2 rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Explore More
            </button>
          </div>
        </div>
      </div>
      <div class="card border-0 col-12 col-sm-4 bg-unset mt-sm-2">
        <div class="box-shadow rounded card-body">
          <h5 class="card-title text-center">COVID-19 Chatbot</h5>
          <div class="mt-4">
            <img
              class="w-100"
              src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/chatbot.png/:/cr=t:6.68%25,l:0%25,w:100%25,h:67.21%25/rs=w:388,h:291.72932330827064,cg:true"
              alt=""
              height="238"
            />
          </div>
          <div class="text-center mt-4">
            <button
              class="btn btn-outline-dark py-2 px-5 border-2 rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#chatbotModal"
            >
              Explore More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container py-8">
  <div class="col-md-12 text-center mb-5">
    <h1>Exploring the Covid-19 data for World & India</h1>
  </div>
  <div class="col-md-12">
    <div class="col-md-12 my-auto">
      <div class="row">
        <div class="card border-0 col-12 col-md-6">
          <div class="card-body">
            <div class="mt-4 text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  class="box-shadow rounded-circle"
                  src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Turquoise%20Map%20Travel%20Trifold%20Brochure.png/:/cr=t:0%25,l:11.38%25,w:77.25%25,h:100%25/rs=w:365,h:365,cg:true"
                  alt=""
                  width="317"
                  height="317"
                />
              </a>
            </div>
            <div class="text-center mt-4">
              <h4 class="card-title text-center">World Data</h4>
            </div>
          </div>
        </div>
        <div class="card border-0 col-12 col-md-6">
          <div class="card-body">
            <div class="mt-4 text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  src="/assets/images/india.svg"
                  alt=""
                  width="317"
                  height="317"
                />
              </a>
            </div>
            <div class="text-center mt-4">
              <h4 class="card-title text-center">India Data</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mb-5">
        <a
          [routerLink]="['/covid-analytics/dashboard-analytics']"
          class="btn btn-outline-dark py-2 px-5 border-2 rounded-pill"
        >
          Explore More
        </a>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="col-md-12 col-12 position-relative">
    <div>
      <img
        src="/assets/images/banner4.jpg "
        class="w-100 position-absolute"
        height="220"
      />
      <div class="position-relative">
        <div class="black-layer w-100"></div>
        <div class="backgournd-image-content">
          <h1 class="font-sm-16">ADQ'S PREVALANCE ON DATA ANALYTICS AND AI</h1>
          <p class="w-50 mx-auto font-sm-12">
            Transform your data into the future with ADQ! We thrive on providing
            custom solutions by dredging innovation and enabling you to think
            bigger and acquire more valuable insights.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container py-8">
  <div class="col-md-12 text-left border-bottom border-2 mb-md-5 mt-sm-2">
    <h4 class="fs-4">Quick Analysis</h4>
  </div>
  <div class="col-md-12">
    <div>
      <div
        id="carousels"
        class="carousel slide w-75 mx-auto"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div class="carousel-inner">
          <button
            class="carousel-control-prev position-absolute"
            data-bs-target="#carousels"
            type="button"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon bg-dark"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next position-absolute"
            data-bs-target="#carousels"
            type="button"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon bg-dark"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
          <div class="carousel-item active">
            <a
              target="_blank"
              [routerLink]="['/covid-analytics/dashboard-analytics']"
            >
              <img
                class="d-block w-100"
                src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide22.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1300,h:800"
                alt="First slide"
              />
            </a>
            <div class="d-md-block quick-analytic-text">
              <h5 class="">India Covid Timeline</h5>
              <p>
                The Map allows visualizing India's COVID-19 cases with respect
                to the time period.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <a
              target="_blank"
              [routerLink]="['/covid-analytics/dashboard-analytics']"
            >
              <img
                class="d-block w-100"
                src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide30.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1300,h:800"
                alt="Second slide "
              />
            </a>
            <div class="d-md-block quick-analytic-text">
              <h5>World Wide Covid Map</h5>
              <p>
                The Map allows visualizing the world countries confirmed,
                recovered, and death cases with respect to the regions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <a
              target="_blank"
              [routerLink]="['/covid-analytics/dashboard-analytics']"
            >
              <img
                class="d-block w-100"
                src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide23.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1300,h:800"
                alt="Third slide"
              />
            </a>
            <div class="d-md-block quick-analytic-text">
              <h5>World Covid Timeline​</h5>
              <p>
                The Map allows visualizing the world COVID-19 cases with respect
                to the time period.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container py-8">
  <div class="col-md-12 text-left border-bottom border-2 mb-md-5">
    <h4 class="fs-4">World Countries Embraced COVID-19 The Most & The Least</h4>
  </div>
  <div class="col-md-12">
    <div class="col-md-12 my-auto">
      <div class="row">
        <div class="card border-0 col-12 col-md-6">
          <div class="card-body">
            <div class="mt-4 text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide31.jpg/:/rs=w:600,h:300,cg:true,m/cr=w:600,h:300"
                  alt=""
                  class="w-100 box-shadow"
                />
              </a>
            </div>
            <div class="text-center mt-4">
              <p class="text-center">
                Explore the information of confirmed, recovered and death cases
                across the top 10 countries
              </p>
            </div>
          </div>
        </div>
        <div class="card border-0 col-12 col-md-6">
          <div class="card-body">
            <div class="mt-4 text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  class="w-100 box-shadow"
                  src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide32.jpg/:/rs=w:600,h:300,cg:true,m/cr=w:600,h:300"
                  alt=""
                />
              </a>
            </div>
            <div class="text-center mt-4">
              <p class="text-center">
                Explore the information of confirmed, recovered and death cases
                across the bottom 10 countries
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center my-3">
        <a
          class="
            btn btn-outline-dark
            py-2
            px-5
            border-2
            rounded-pill
            fs-14
            font-weight-bold
          "
          [routerLink]="['/covid-analytics/dashboard-analytics']"
        >
          Find Out More
        </a>
      </div>
    </div>
  </div>
</div>

<div class="container py-8">
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="col-md-12 text-left mb-md-5 border-bottom border-2 mt-sm-2">
        <h4 class="fs-4">COVID-19 India Live Dashboard</h4>
      </div>
      <div class="col-md-12">
        <div class="col-md-12 my-auto">
          <div class="row">
            <div class="card border-0 col-12">
              <div class="card-body">
                <div class="text-left">
                  <h4 class="fs-6 fw-bold">A 360* view of Indian data!</h4>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    [routerLink]="['/covid-analytics/dashboard-analytics']"
                  >
                    <img
                      src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide33.jpg/:/rs=w:814,h:407,cg:true,m/cr=w:814,h:407"
                      alt=""
                      class="w-100 imgs"
                    />
                  </a>
                </div>
                <div class="text-center mt-4">
                  <p class="text-center">
                    A holistic view of Total cases with respect to the states
                    and time period
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-12">
      <div class="col-md-12 text-left border-bottom border-2 mb-md-5">
        <h4 class="fs-4">COVID-19 World Live Dashboard</h4>
      </div>
      <div class="col-md-12">
        <div class="col-md-12 my-auto">
          <div class="row">
            <div class="card border-0 col-12">
              <div class="card-body">
                <div class="text-left">
                  <h4 class="fs-6 fw-bold">A 360* view of World data!</h4>
                </div>
                <div class="text-center">
                  <a
                    target="_blank"
                    [routerLink]="['/covid-analytics/dashboard-analytics']"
                  >
                    <img
                      src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide34.jpg/:/rs=w:814,h:407,cg:true,m/cr=w:814,h:407"
                      alt=""
                      class="w-100 imgs"
                    />
                  </a>
                </div>
                <div class="text-center mt-4">
                  <p class="text-center">
                    A holistic view of Total cases with respect to the countries
                    and time period
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container py-8">
  <div class="col-md-12 text-left border-bottom border-2 mb-md-5">
    <h4 class="fs-4">Bar Race</h4>
  </div>
  <div class="col-md-12">
    <div class="col-md-12 my-auto">
      <div class="row mt-md-5">
        <div class="card border-0 col-12 col-md-4">
          <div class="card-body">
            <div class="text-left">
              <h4 class="fs-6 fw-bold mt-2">India Confirmed Bar Race​</h4>
            </div>
            <div class="text-left mt-4">
              <p class="text-justify">
                The India Bar Race gives information about the states that are
                dominating with the highest number of cases on day to day basis
                and the overall count of the country.
              </p>
            </div>
          </div>
        </div>
        <div class="card border-0 col-12 col-md-8">
          <div class="card-body">
            <div class="text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide24.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1023,cg:true"
                  alt=""
                  class="w-100 box-shadow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="card border-0 col-12 col-md-8">
          <div class="card-body">
            <div class="text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide25.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1023,cg:true"
                  alt=""
                  class="w-100 box-shadow"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="card border-0 col-12 col-md-4">
          <div class="card-body">
            <div class="text-left">
              <h4 class="fs-6 fw-bold mt-2">World Confirmed Bar race</h4>
            </div>
            <div class="text-left mt-4">
              <p class="text-justify">
                The World Bar Race gives information about the countries with
                confirmed cases that are dominating with the highest number of
                cases on day to day basis and the overall count of the world.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-md-5">
        <div class="card border-0 col-12 col-md-4">
          <div class="card-body">
            <div class="text-left">
              <h4 class="fs-6 fw-bold mt-2">World Recovered Bar Race​</h4>
            </div>
            <div class="text-left mt-4">
              <p class="text-justify">
                The Country Bar Race gives information about the country's
                highest number of recovered cases on day to day basis and the
                overall count of the world.
              </p>
            </div>
          </div>
        </div>
        <div class="card border-0 col-12 col-md-8">
          <div class="card-body">
            <div class="text-center">
              <a
                target="_blank"
                [routerLink]="['/covid-analytics/dashboard-analytics']"
              >
                <img
                  src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide26.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1023,cg:true"
                  alt=""
                  class="w-100 box-shadow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container py-8 mb-5">
  <div
    id="world"
    class="col-md-12 text-left mb-md-5 border-bottom border-2 mt-sm-2"
  >
    <h1 class="fs-4">India COVID-19 Statistics</h1>
  </div>
  <div
    id="carouseles"
    class="carousel carousel-dark slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="10000">
        <div class="row">
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6">
                  India COVID-19 Confirmed Map
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide27.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6">
                  India COVID-19 Recovered Map​
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide28.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div class="w-75 mx-auto">
                <h4 class="text-left fw-normal fs-6">
                  India COVID-19 Death Map​
                </h4>
              </div>

              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide29.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="2000">
        <div class="row">
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6">
                  India COVID-19 Confirmed Map
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide27.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6">
                  India COVID-19 Recovered Map​
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide28.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div class="w-75 mx-auto">
                <h4 class="text-left fw-normal fs-6">
                  India COVID-19 Death Map​
                </h4>
              </div>

              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide29.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouseles"
      data-bs-slide="prev"
    >
      <span
        class="carousel-control-prev-icon bg-secondary"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouseles"
      data-bs-slide="next"
    >
      <span
        class="carousel-control-next-icon bg-secondary"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div class="text-center mt-md-5 my-3">
    <a
      [routerLink]="['/covid-analytics/dashboard-analytics']"
      class="btn btn-outline-dark py-2 px-5 border-2 rounded-pill fs-14"
    >
      Find Out More
    </a>
  </div>

  <!-- end main content  -->
</div>

<div class="container py-8 mb-5">
  <div
    id="world"
    class="col-md-12 text-left mb-md-5 border-bottom border-2 mt-sm-2"
  >
    <h1 class="fs-4">World COVID-19 Statistics</h1>
  </div>
  <div
    id="carouselExampleDark"
    class="carousel carousel-dark slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="10000">
        <div class="row">
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6 slider-header">
                  World COVID-19 Map
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide30-0001.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6 slider-header">
                  World COVID-19 Top 10​ Countries
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide31-0001.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6 slider-header">
                  COVID-19 Country Wise
                </h4>
              </div>

              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide35.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="2000">
        <div class="row">
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6 slider-header">
                  World COVID-19 Confirmed​
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide25-0001.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div>
                <h4 class="text-left fw-normal fs-6 slider-header">
                  World COVID-19 Recovered
                </h4>
              </div>
              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide26-0001.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="card border-0 col-12 col-md-4">
            <div class="card-body">
              <div class="mx-auto">
                <h4 class="text-left fw-normal fs-6 slider-header">
                  World COVID-19 Timeline​​
                </h4>
              </div>

              <div class="text-center">
                <a
                  target="_blank"
                  [routerLink]="['/covid-analytics/dashboard-analytics']"
                >
                  <img
                    class="w-100"
                    src="https://img1.wsimg.com/isteam/ip/943f6433-c999-4a65-a7ae-59361730c112/Slide23-0001.jpg/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194/qt=q:34"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleDark"
      data-bs-slide="prev"
    >
      <span
        class="carousel-control-prev-icon bg-secondary"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleDark"
      data-bs-slide="next"
    >
      <span
        class="carousel-control-next-icon bg-secondary"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div class="text-center mt-md-5 my-1">
    <a
      [routerLink]="['/covid-analytics/dashboard-analytics']"
      class="btn btn-outline-dark py-2 px-5 border-2 rounded-pill fs-14"
    >
      Find Out More
    </a>
  </div>
</div>
<!-- end main content  -->
<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <form
    [formGroup]="forecastForm"
    (ngSubmit)="forecastSubmit(forecastForm.value)"
  >
    <div class="modal-dialog col-md-12">
      <div class="modal-content rounded-lg">
        <div class="modal-header">
          <h5 class="modal-title fs-14" id="exampleModalLabel">
            Forecasting of the covid cases for India and worldwide
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body fs-12">
          <label for="forecastNo">Forecast days </label>
          <input
            class="text px-1 py-1 w-25 ms-3"
            id="forecastNo"
            type="number"
            formControlName="number"
            placeholder="Enter number"
            max="15"
            min="1"
            (input)="validationForecast($event)"
          />
          <span class="fs-12 forecast-message">Input days between 1 to 15</span>
          <div class="mt-1">
            <span
              class="fs-12 text-danger"
              *ngIf="
                (forecastControls.number.errors &&
                  forecastControls.number.touched) ||
                !isValid
              "
            >
              Please provide the days between 1 to 15
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            data-bs-dismiss="modal"
            class="btn btn-primary fs-14"
            [disabled]="forecastControls.number.errors || !isValid"
          >
            Submit
          </button>
          <button
            type="button"
            class="btn btn-secondary fs-14"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- end modal -->
<!-- chatbot Modal -->

<div
  class="modal fade"
  id="chatbotModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog col-md-12">
    <div class="modal-content rounded-lg">
      <form
        [formGroup]="chatbotForm"
        (ngSubmit)="chatbotSubmit(chatbotForm.value)"
      >
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Chatbot</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body fs-12">
          <label class="fs-6 font-weight-bold" for="question"
            >Question :
          </label>
          <input
            rows="1"
            class="text-area px-1 py-1 w-75 ml-3"
            id="question"
            type="text"
            formControlName="question"
            placeholder="Enter Your Questions ?"
          />
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary fs-14"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
          <button
            type="button"
            class="btn btn-secondary fs-14"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <app-footer></app-footer> -->

<!-- end chatbotModal -->
