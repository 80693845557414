import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { ForecastComponent } from "./modules/dashboard/components/forecast/forecast.component";
import { HomeComponent } from "./modules/dashboard/components/home/home.component";

const routes: Routes = [
  {
    path: "covid-analytics",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "**",
    redirectTo: "/covid-analytics/home",
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/covid-analytics/home",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
