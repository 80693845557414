<div class="main-container" style="margin-top: 8%;">
  <div class="container mt-4">
    <div class="col-md-12 d-flex mt-5 pt-3">
      <button
        class="btn btn-outline-primary btn-back fs-14"
        routerLink="/covid-analytics/home"
      >
        <i class="arrow-left"></i>
        Back
      </button>
      <div class="w-auto ms-auto me-0">
        <button
          class="btn btn-outline-secondary me-3 fs-14"
          data-bs-toggle="modal"
          data-bs-target="#forecastModal"
        >
          Forecast Covid-19
        </button>
        <a
          target="_blank"
          class="btn btn-outline-secondary fs-14"
          data-bs-toggle="modal"
          data-bs-target="#chatbotModal"
        >
          Covid-19 Chatbot
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="my-5">
      <iframe
        width="100%"
        height="500"
        src="https://app.powerbi.com/view?r=eyJrIjoiMTc3OWE0ZDYtMzQzMS00ZjQ1LWEyYWEtMzkzOTk2OWNiMGIzIiwidCI6ImVjMWMyMGUwLTgzZWQtNDE3ZS04NGY3LTNiMmQ1OWM5MTg4MiJ9"
      ></iframe>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="chatbotModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog col-md-12">
    <div class="modal-content rounded-lg">
      <form
        [formGroup]="chatbotForm"
        (ngSubmit)="chatbotSubmit(chatbotForm.value)"
      >
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Chatbot</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body fs-12">
          <label class="fs-6 font-weight-bold" for="question"
            >Question :
          </label>
          <input
            rows="1"
            class="text-area px-1 py-1 w-75 ml-3"
            id="question"
            type="text"
            formControlName="question"
            placeholder="Enter Your Questions ?"
          />
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary fs-14"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
          <button
            type="button"
            class="btn btn-secondary fs-14"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="forecastModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <form
    [formGroup]="forecastForm"
    (ngSubmit)="forecastSubmit(forecastForm.value)"
  >
    <div class="modal-dialog col-md-12">
      <div class="modal-content rounded-lg">
        <div class="modal-header">
          <h5 class="modal-title fs-14" id="exampleModalLabel">
            Forecasting of the covid cases for India and worldwide
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body fs-12">
          <label for="forecastNo">Forecast number of day </label>
          <input
            class="text px-1 py-1 w-25 ms-3"
            id="forecastNo"
            type="number"
            formControlName="number"
            placeholder="Enter number"
            max="15"
            min="1"
          />
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            data-bs-dismiss="modal"
            class="btn btn-primary fs-14"
          >
            Submit
          </button>
          <button
            type="button"
            class="btn btn-secondary fs-14"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
