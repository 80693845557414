import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ForecastService } from 'src/app/core/services/forecast.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  chatbotForm: FormGroup;
  forecastForm: FormGroup;
  isValid = true;
  imgSliderArray: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private forcastService: ForecastService
  ) {
    this.imgSliderArray =
    [
      { img: 'assets/images/banner1.jpg'},
      { img: 'assets/images/banner2.png'},
      { img: 'assets/images/banner3.jpg'},
      { img: 'assets/images/banner4.jpg'},
      { img: 'assets/images/banner5.jpg'},
    ];

    this.chatbotForm = this.formBuilder.group({
      question: ['', Validators.required],
    });
    this.forecastForm = this.formBuilder.group({
      number: ['', [Validators.required, Validators.pattern('[0-9]{1,2}')]],
    });
  }

  ngOnInit(): void {}
  get forecastControls()
  {
    return this.forecastForm.controls;
  }
  chatbotSubmit(data: any) {
    localStorage.setItem('chatbotQuestion', data.question);
    this.router.navigate(['/covid-analytics/chatbot']);
  }
  forecastSubmit(data: any) {
    if (this.forecastForm.valid && this.isValid)
    {
      localStorage.setItem('numberOfForecast', data.number);
      this.router.navigateByUrl('/covid-analytics/forecast');
    }
  }
  validationForecast(data)
  {
     const dataValue = parseInt(data.target.value, 10) ;
     if ((0 < dataValue &&  dataValue <= 15) && this.forecastForm.valid  )
     {
      this.isValid = true;
     }
     else if ((0 >= dataValue || dataValue > 15) || this.forecastForm.invalid)
     {
      this.isValid = false;
     }
  }
}
