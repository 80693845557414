import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ChatbotService } from "src/app/core/services/chatbot.service";

@Component({
  selector: "app-chatbot",
  templateUrl: "./chatbot.component.html",
  styleUrls: ["./chatbot.component.scss"],
})
export class ChatbotComponent implements OnInit {
  answersList = [];
  question: string = "";
  searchForm: FormGroup;
  forecastForm: FormGroup;
  constructor(
    private chatbotService: ChatbotService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.forecastForm = this.formBuilder.group({
      number: [""],
    });
    this.searchForm = this.formBuilder.group({
      questionfield: [""],
    });
    if (
      localStorage.getItem("chatbotQuestion") !== null &&
      localStorage.getItem("chatbotQuestion") !== undefined &&
      localStorage.getItem("chatbotQuestion") !== ""
    ) {
      this.question = localStorage.getItem("chatbotQuestion");
      this.chatbotService.getAnswers(this.question).subscribe((res) => {
        this.answersList = res.Answers;
        localStorage.removeItem("chatbotQuestion");
      });
    }
  }

  ngOnInit(): void {
    // window.scrollTo(0, 0);
  }
  get formControls() {
    return this.searchForm.controls;
  }
  sendQuestion(formData: any) {
    this.answersList = [];
    this.question = formData.questionfield;
    this.chatbotService.getAnswers(this.question).subscribe((res) => {
      this.answersList = res.Answers;
      this.clearTextBox();
    });
  }
  clearTextBox() {
    this.formControls.questionfield.setValue("");
  }
  forecastSubmit(data: any) {
    localStorage.setItem("numberOfForecast", data.number);
    this.router.navigateByUrl("/covid-analytics/forecast");
  }
}
