import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  chatbotForm: FormGroup;
  forecastForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.chatbotForm = this.formBuilder.group({
      question: [""],
    });
    this.forecastForm = this.formBuilder.group({
      number: [""],
    });
  }

  ngOnInit(): void {
    document.body.scrollTop = 0;
  }
  chatbotSubmit(data: any) {
    localStorage.setItem("chatbotQuestion", data.question);
    this.router.navigate(["/covid-analytics/chatbot"]);
  }
  forecastSubmit(data: any) {
    localStorage.setItem("numberOfForecast", data.number);
    this.router.navigateByUrl("/covid-analytics/forecast");
  }
}
