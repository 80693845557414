<!-- FOOTER -->
<footer id="footer" class="footer">
  <div class="first-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3 me-lg-5">
          <div class="widget megacourse">
            <h3 class="md footer-widget">CONTACT US</h3>
            <div class="row address-container mb-3">
              <div class="col-2">
                <span>
                  <svg
                    fill="#50af4d"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    width="26px"
                    height="26px"
                  >
                    <path
                      d="M 32 3 L 1 28 L 1.4921875 28.654297 C 2.8591875 30.477297 5.4694688 30.791703 7.2304688 29.345703 L 32 9 L 56.769531 29.345703 C 58.530531 30.791703 61.140812 30.477297 62.507812 28.654297 L 63 28 L 54 20.742188 L 54 8 L 45 8 L 45 13.484375 L 32 3 z M 32 13 L 8 32 L 8 56 L 56 56 L 56 35 L 32 13 z M 26 34 L 38 34 L 38 52 L 26 52 L 26 34 z"
                    />
                  </svg>
                  <!-- <i
                    style="color: #50af4d; font-size: 26px"
                    class="fas fa-home"
                  ></i> -->
                </span>
              </div>
              <div class="col-10">
                <span>
                  Address : 4th floor, Sun towers, Plot no-22, sector-1, Huda
                  techno enclave, Madhapur, Hyderabad , India - 500081
                </span>
              </div>
            </div>
            <div class="row address-container">
              <div class="col-2">
                <span
                  ><svg
                    fill="#50af4d"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    width="26px"
                    height="26px"
                  >
                    <path
                      d="M 32 3 L 1 28 L 1.4921875 28.654297 C 2.8591875 30.477297 5.4694688 30.791703 7.2304688 29.345703 L 32 9 L 56.769531 29.345703 C 58.530531 30.791703 61.140812 30.477297 62.507812 28.654297 L 63 28 L 54 20.742188 L 54 8 L 45 8 L 45 13.484375 L 32 3 z M 32 13 L 8 32 L 8 56 L 56 56 L 56 35 L 32 13 z M 26 34 L 38 34 L 38 52 L 26 52 L 26 34 z"
                    />
                  </svg>
                </span>
              </div>
              <div class="col-10 pr-0">
                <span>
                  Address : 500,Westover Dr, #1514, Sanford North Carolina 27330
                  U.S.A
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 ms-lg-5">
          <div class="widget widget_latest_new">
            <h3 class="sm footer-widget">Site Links</h3>
            <div>
              <a target="_blank" href="https://adqservices.com/">
                <span>About US</span>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://adqservices.com/contactus">
                <span>Terms & Conditions</span>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://adqservices.com/contactus">
                <span>Privacy Policy</span>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://adqservices.com/contactus">
                <span>Careers</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 text-center">
          <div>
            <a href="/dashboard/home">
              <img
                src="../../../assets/images/adqlogo.png"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="second-footer">
    <div class="container">
      <div class="col-12 col-lg-12s text-center">
        <p class="copyright w-100">
          Copyright © 2021 Covid-19 Analytics - All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</footer>
<!-- END / FOOTER -->
