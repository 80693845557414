<!-- <div id="header" class="header">
  <div class="container">
    <div class="logo">
     
    </div>

    <div class="navigation">
      <a  href="http://adqservices.com/">
        <span> Home </span>
      </a>
      <a href="http://adqservices.com/about-us">
        <span> About Us </span>
      </a>
      <a href="http://adqservices.com/contact-us">
        <span> Contact </span>
      </a>
    </div>
  </div>
</div> -->
<nav id="header" class="navbar sticky-top navbar-expand-lg">
  <div class="container ms-0">
    <a class="navbar-brand" href="#"> <img
      src="../../../assets/images/adqlogo.png"
      alt="logo"
    /></a>
    <button class="btn  d-md-none ms-auto" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
      
      <!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" /></svg>
    </button>
  </div>
  <div class="collapse  d-md-block" id="collapseExample">
    <div class="navigation">
      <a class="menu-item" target="_blank" href="https://adqservices.com/">
        <span> Home </span>
      </a>
      <a class="menu-item" target="_blank" href="https://adqservices.com/about-us">
        <span> About Us </span>
      </a>
      <a class="menu-item" target="_blank" href="https://adqservices.com/contactus">
        <span> Contact </span>
      </a>
    </div>
  </div>
</nav>
