<div class="p-4 main-container" style="margin-top: 6%;">
  <div class="col-md-12 d-flex mt-2">
    <button
      class="btn btn-outline-primary btn-back header-btn"
      routerLink="/covid-analytics/home"
    >
      <i class="arrow-left"></i>
      Back
    </button>
    <div class="w-auto ms-auto me-0">
      <button
        class="btn btn-outline-secondary me-md-3 header-btn"
        data-bs-toggle="modal"
        data-bs-target="#chatbotModal"
      >
        Covid-19 Chatbot
      </button>
      <a
        target="_blank"
        class="btn btn-outline-secondary header-btn"
        routerLink="/covid-analytics/dashboard-analytics"
      >
        Covid-19 Dashboard
      </a>
    </div>
  </div>
  <div class="border-bottom mt-5">
    <div>
      <h1>
        Forecasting of the covid cases for India and worldwide
        <span class="sticky-red">*</span>
      </h1>
    </div>
  </div>
  <!--india auto regressor -->
  <div class="mt-5 border-bottom text-center w-100 h-100">
    <div>
      <h4>
        <b> Covid-19 cases forecasting for India using Auto Regressor </b>
      </h4>
    </div>
    <div class="mx-auto py-md-4 h-md-25 w-md-65">
      <canvas id="myChart" class="w-100 canvas" #mychart></canvas>
    </div>
    <div class="mx-auto my-5 w-100 overflow-auto">
      <table class="table table-bordered">
        <thead class="">
          <tr>
            <th>Date</th>
            <td class="fs-12" *ngFor="let date of india_auto_regressor">
              <span>{{ date.Date }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Forecasted Cases</th>
            <td class="fs-12" *ngFor="let date of india_auto_regressor">
              <span>{{ date["Daily Confirmed"] | number }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- world auto regressor -->
  <div class="mt-5 border-bottom text-center w-100 h-100">
    <div>
      <h4>
        <b> Covid-19 cases forecasting for World using Auto Regressor </b>
      </h4>
    </div>
    <div class="mx-auto py-md-4 h-md-25 w-md-65">
      <canvas id="worldRegressor" class="w-100 canvas" #worldRegressor></canvas>
    </div>
    <div class="mx-auto my-5 w-100 overflow-auto">
      <table class="table table-bordered">
        <thead class="">
          <tr>
            <th>Date</th>
            <td class="fs-12" *ngFor="let date of world_auto_regressor">
              <span>{{ date.Date }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Forecasted Cases</th>
            <td class="fs-12" *ngFor="let date of world_auto_regressor">
              <span>{{ date["Daily Confirmed"] | number }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- india sarimax  -->
  <div class="mt-5 border-bottom text-center w-100 h-100">
    <div>
      <h4>
        <b> Covid-19 cases forecasting for India using SARIMAX </b>
      </h4>
    </div>
    <div class="mx-auto py-md-4 h-md-25 w-md-65">
      <canvas
        id="indiaChartSarimax"
        class="w-100 canvas"
        #indiaChartSarimax
      ></canvas>
    </div>
    <div class="mx-auto my-5 w-100 overflow-auto">
      <table class="table table-bordered">
        <thead class="">
          <tr>
            <th>Date</th>
            <td class="fs-12" *ngFor="let date of india_sarimax">
              <span>{{ date.Date }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Forecasted Cases</th>
            <td class="fs-12" *ngFor="let date of india_sarimax">
              <span>{{ date["Daily Confirmed"] | number }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- world sarimax -->
  <div class="mt-5 border-bottom text-center w-100 h-100">
    <div>
      <h4>
        <b> Covid-19 cases forecasting for World using Sarimax </b>
      </h4>
    </div>
    <div class="mx-auto py-md-4 h-md-25 w-md-65">
      <canvas id="worldSarimax" class="w-100 canvas" #worldSarimax></canvas>
    </div>
    <div class="mx-auto my-5 w-100 overflow-auto">
      <table class="table table-bordered">
        <thead class="">
          <tr>
            <th>Date</th>
            <td class="fs-12" *ngFor="let date of world_sarimax">
              <span>{{ date.Date }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Forecasted Cases</th>
            <td class="fs-12" *ngFor="let date of world_sarimax">
              <span>{{ date["Daily Confirmed"] | number }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- india LSTM  -->
  <div class="mt-5 border-bottom text-center w-100 h-100">
    <div>
      <h4>
        <b> Covid-19 cases forecasting for India using LSTM </b>
      </h4>
    </div>
    <div class="mx-auto py-md-4 h-md-25 w-md-65">
      <canvas id="indiaChartLSTM" class="w-100 canvas" #indiaChartLSTM></canvas>
    </div>
    <div class="mx-auto my-5 w-100 overflow-auto">
      <table class="table table-bordered">
        <thead class="">
          <tr>
            <th>Date</th>
            <td class="fs-12" *ngFor="let date of india_lstm">
              <span>{{ date.Date }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Forecasted Cases</th>
            <td class="fs-12" *ngFor="let date of india_lstm">
              <span>{{ date["Daily Confirmed"] | number }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- world LSTM -->
  <div class="mt-5 border-bottom text-center w-100 h-100">
    <div>
      <h4>
        <b> Covid-19 cases forecasting for World using LSTM </b>
      </h4>
    </div>
    <div class="mx-auto py-md-4 h-md-25 w-md-65">
      <canvas id="worldLSTM" class="w-100 canvas" #worldLSTM></canvas>
    </div>
    <div class="mx-auto my-5 w-100 overflow-auto">
      <table class="table table-bordered">
        <thead class="">
          <tr>
            <th>Date</th>
            <td class="fs-12" *ngFor="let date of world_lstm">
              <span>{{ date.Date }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Forecasted Cases</th>
            <td class="fs-12" *ngFor="let date of world_lstm">
              <span>{{ date["Daily Confirmed"] | number }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div id="model_performance" class="row">
    <!-- india Model Performance -->
    <div class="border-bottom text-center col-12 col-md-6 mb-5 mt-5">
      <div>
        <h4>
          <b>Model performance for India </b>
        </h4>
      </div>
      <div class="mx-auto py-4">
        <canvas id="indiaPerformace" class="w-100" #indiaPerformace></canvas>
      </div>
    </div>
    <!-- world Model Performance -->
    <div class="mt-5 border-bottom text-center col-12 col-md-6 mb-5">
      <div>
        <h4>
          <b>Model performance for World </b>
        </h4>
      </div>
      <div class="mx-auto py-4">
        <canvas id="worldPerformace" class="w-100" #worldPerformace></canvas>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="container">
      <div>
        <i class="sticky-red-bottom">* </i>
        <span>Source</span>
        <ul style="list-style-type: none">
          <li class="">
            <a
              class="overflow-wrap-break"
              href="https://github.com/CSSEGISandData/COVID-19"
              target="_blank"
            >
              https://github.com/CSSEGISandData/COVID-19</a
            >
          </li>
          <li class="">
            <a
              class="overflow-wrap-break"
              href="https://github.com/CSSEGISandData/COVID-19"
              target="_blank"
            >
              https://www.kaggle.com/sudalairajkumar/covid19-in-india.
            </a>
          </li>
          <li class="">
            <a href="https://data.covid19india.org/" target="_blank"
              >https://data.covid19india.org/</a
            >
          </li>
        </ul>
      </div>
      <div>
        <i class="sticky-red-bottom">* </i>
        <span>
          Data from the above sources has been used for forecasting the
          confirmed cases using Auto Regressor(AR),
        </span>
      </div>
      <div>
        <span class="ms-3">
          Seasonal Auto Regressive Integrated Moving Average with
          exogenous(SARIMAX) and Long Short Term Memory(LSTM) models.
        </span>
      </div>
      <div>
        <i class="sticky-red-bottom">* </i>
        <span class="fw-bold"> Note: </span>
        <span> The Forecasted cases may not be 100% Accurate. </span>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="chatbotModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog col-md-12">
    <div class="modal-content rounded-lg">
      <form
        [formGroup]="chatbotForm"
        (ngSubmit)="chatbotSubmit(chatbotForm.value)"
      >
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Chatbot</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body fs-12">
          <label class="fs-6 font-weight-bold" for="question"
            >Question :
          </label>
          <input
            rows="1"
            class="text-area px-1 py-1 w-75 ml-3"
            id="question"
            type="text"
            formControlName="question"
            placeholder="Enter Your Questions ?"
          />
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary fs-14"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
          <button
            type="button"
            class="btn btn-secondary fs-14"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
