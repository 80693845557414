import { Component, OnInit, ViewChild } from "@angular/core";
import Chart from "chart.js/auto";
import { ForecastService } from "src/app/core/services/forecast.service";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import "chartjs-adapter-moment";
import { FormBuilder, FormGroup } from "@angular/forms";
declare var $: any;
@Component({
  selector: "app-forecast",
  templateUrl: "./forecast.component.html",
  styleUrls: ["./forecast.component.scss"],
})
export class ForecastComponent implements OnInit {
  chatbotForm: FormGroup;
  @ViewChild("mychart") mychart;
  @ViewChild("worldRegressor") worldRegressor;
  worldRegressorCanvas: any;
  ctxWorld: any;
  canvas: any;
  ctx: any;
  indiaCanvasSarimax: any;
  indiaContextSarimax: any;
  indiaCanvasLSTM: any;
  indiaContextLSTM: any;
  worldCanvasSarimax: any;
  worldContextSarimax: any;
  worldCanvasLSTM: any;
  worldContextLSTM: any;
  indiaPerformanceCanvas: any;
  indiaPerformanceContext: any;
  worldPerformanceCanvas: any;
  worldPerformanceContext: any;
  sarimax_india_data: any;
  auto_regressor_india_data = [];
  lstm_india_data = [];
  auto_regressor_world_data = [];
  sarimax_world_data = [];
  lstm_world_data = [];
  modal_performation_india_original = [];
  modal_performation_india_sarimax = [];
  modal_performation_india_LSTM = [];
  modal_performation_india_AR = [];
  modal_performation_world_original = [];
  modal_performation_world_sarimax = [];
  modal_performation_world_LSTM = [];
  modal_performation_world_AR = [];
  data = [];
  worldOldData = [];
  oldDataIndia = [];
  oldDataWorld = [];
  oldLastpoint = {};
  india_auto_regressor = [];
  india_sarimax = [];
  india_lstm = [];
  world_auto_regressor = [];
  world_sarimax = [];
  world_lstm = [];
  modal_performation_india = [];
  modal_performation_world = [];
  totalDuration = 5000;
  delayBetweenPoints: any;
  delayBetweenPointsWolrd = this.totalDuration / this.worldOldData.length;
  previousY = (ctx) =>
    ctx.index === 0
      ? ctx.chart.scales.y
      : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1];
  constructor(
    private forcastService: ForecastService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.chatbotForm = this.formBuilder.group({
      question: [""],
    });
  }
  ngOnInit(): void {
    document.body.scrollTop = 0;
    this.indiaCanvasSarimax = <HTMLCanvasElement>(
      document.getElementById("indiaChartSarimax")
    );
    this.canvas = <HTMLCanvasElement>document.getElementById("myChart");
    this.indiaCanvasLSTM = <HTMLCanvasElement>(
      document.getElementById("indiaChartLSTM")
    );
    this.worldRegressorCanvas = <HTMLCanvasElement>(
      document.getElementById("worldRegressor")
    );

    this.worldCanvasSarimax = <HTMLCanvasElement>(
      document.getElementById("worldSarimax")
    );
    this.worldCanvasLSTM = <HTMLCanvasElement>(
      document.getElementById("worldLSTM")
    );
  }
  ngAfterViewInit() {
    var num: number = parseInt(localStorage.getItem("numberOfForecast"));
    if (!!num && num) {
      var indiadata;
      var worldData;

      this.sarimax_india_data = [];

      this.oldDataIndia = [];

      this.forcastService.getForecastData(num).subscribe((res) => {
        this.data = [];
        indiadata = res.India;
        worldData = res.World;
        this.modal_performation_india = JSON.parse(
          res["model performances India"]
        );
        this.modal_performation_world = JSON.parse(
          res["model performances World"]
        );
        this.oldDataIndia = JSON.parse(indiadata["Previous Data"]);

        this.modal_performation_india.forEach((ele) => {
          this.modal_performation_india_original.push({
            x: ele.Date,
            y: ele["Original Data"],
          });
          this.modal_performation_india_sarimax.push({
            x: ele.Date,
            y: ele["Sarimax"],
          });
          this.modal_performation_india_LSTM.push({
            x: ele.Date,
            y: ele["LSTM"],
          });
          this.modal_performation_india_AR.push({
            x: ele.Date,
            y: ele["Auto Regressor"],
          });
        });

        this.modal_performation_world.forEach((ele) => {
          this.modal_performation_world_original.push({
            x: ele.Date,
            y: ele["Original Data"],
          });
          this.modal_performation_world_sarimax.push({
            x: ele.Date,
            y: ele["Sarimax"],
          });
          this.modal_performation_world_LSTM.push({
            x: ele.Date,
            y: ele["LSTM"],
          });
          this.modal_performation_world_AR.push({
            x: ele.Date,
            y: ele["Auto Regressor"],
          });
        });

        //old for india
        this.oldDataIndia.forEach((element) => {
          this.data.push({
            x: element.Date,
            y: element["Daily Confirmed"],
          });
        });

        this.auto_regressor_india_data.push({
          x: this.data[this.data.length - 1].x,
          y: this.data[this.data.length - 1].y,
        });
        this.sarimax_india_data.push({
          x: this.data[this.data.length - 1].x,
          y: this.data[this.data.length - 1].y,
        });
        this.lstm_india_data.push({
          x: this.data[this.data.length - 1].x,
          y: this.data[this.data.length - 1].y,
        });
        // Data Auto Regressor India
        this.india_auto_regressor = JSON.parse(
          indiadata["Auto_Regressor_India_Forecast"]
        );

        this.india_auto_regressor.forEach((ele) => {
          this.auto_regressor_india_data.push({
            x: ele.Date,
            y: ele["Daily Confirmed"],
          });
        });

        // data india sarimax
        this.india_sarimax = JSON.parse(indiadata["Sarimax_India_Forecast"]);

        this.india_sarimax.forEach((ele) => {
          this.sarimax_india_data.push({
            x: ele.Date,
            y: ele["Daily Confirmed"],
          });
        });

        // data india lstm
        this.india_lstm = JSON.parse(indiadata["LSTM_India_Forecast"]);
        this.india_lstm.forEach((ele) => {
          this.lstm_india_data.push({
            x: ele.Date,
            y: ele["Daily Confirmed"],
          });
        });

        // old for world
        this.oldDataWorld = JSON.parse(worldData["Previous Data"]);
        this.oldDataWorld.forEach((element) => {
          this.worldOldData.push({
            x: element.Date,
            y: element["Daily Confirmed"],
          });
        });

        // Data Auto  Regressor World
        this.auto_regressor_world_data.push({
          x: this.worldOldData[this.worldOldData.length - 1].x,
          y: this.worldOldData[this.worldOldData.length - 1].y,
        });
        this.world_auto_regressor = JSON.parse(
          worldData["Auto_Regressor_World_Forecast"]
        );

        this.world_auto_regressor.forEach((ele) => {
          this.auto_regressor_world_data.push({
            x: ele.Date,
            y: ele["Daily Confirmed"],
          });
        });
        // Data Sarimax World
        this.sarimax_world_data.push({
          x: this.worldOldData[this.worldOldData.length - 1].x,
          y: this.worldOldData[this.worldOldData.length - 1].y,
        });
        this.world_sarimax = JSON.parse(worldData["Sarimax_World_Forecast"]);
        this.world_sarimax.forEach((ele) => {
          this.sarimax_world_data.push({
            x: ele.Date,
            y: ele["Daily Confirmed"],
          });
        });

        // Data LSTM World
        this.lstm_world_data.push({
          x: this.worldOldData[this.worldOldData.length - 1].x,
          y: this.worldOldData[this.worldOldData.length - 1].y,
        });
        this.world_lstm = JSON.parse(worldData["LSTM_World_Forecast"]);
        this.world_lstm.forEach((ele) => {
          this.lstm_world_data.push({
            x: ele.Date,
            y: ele["Daily Confirmed"],
          });
        });
      });

      setTimeout(() => {
        var that = this;
        var sarimax_india_oTop =
          $("#indiaChartSarimax").offset().top - window.innerHeight;
        var auto_regressor_india_oTop =
          $("#myChart").offset().top - window.innerHeight;
        var lstm_india_oTop =
          $("#indiaChartLSTM").offset().top - window.innerHeight;
        var auto_regressor_world_oTop =
          $("#worldRegressor").offset().top - window.innerHeight;
        var sarimax_world_oTop =
          $("#worldSarimax").offset().top - window.innerHeight;
        var lstm_world_oTop = $("#worldLSTM").offset().top - window.innerHeight;
        var model_performance =
          $("#model_performance").offset().top - window.innerHeight;

        var chartHidden = true;
        var chartHidden_ar_india = true;
        var chartHidden_lstm_india = true;
        var chartHidden_ar_worldA = true;
        var chartHidden_sarimax_world = true;
        var chartHidden_lstm_world = true;
        var chartHidden_model_performance = true;
        $(window).on("scroll", function (e) {
          var pTop = $(window).scrollTop();

          if (pTop > auto_regressor_india_oTop && chartHidden_ar_india) {
            chartHidden_ar_india = false;
            that.auto_regressor_india();
          }

          if (pTop > sarimax_india_oTop && chartHidden) {
            chartHidden = false;
            that.sarimaxIndia();
          }

          if (pTop > lstm_india_oTop && chartHidden_lstm_india) {
            chartHidden_lstm_india = false;
            that.lstm_india();
          }

          if (pTop > auto_regressor_world_oTop && chartHidden_ar_worldA) {
            chartHidden_ar_worldA = false;
            that.auto_regressor_world();
          }

          if (pTop > sarimax_world_oTop && chartHidden_sarimax_world) {
            chartHidden_sarimax_world = false;
            that.sarimax_world();
          }

          if (pTop > lstm_world_oTop && chartHidden_lstm_world) {
            chartHidden_lstm_world = false;
            that.lstm_wolrd();
          }
          if (pTop > model_performance && chartHidden_model_performance) {
            chartHidden_model_performance = false;
            that.modelperformance();
          }
        });
      }, 500);
    }
  }

  // ar for india

  auto_regressor_india() {
    const delayBetweenPoints = this.totalDuration / this.data.length;

    setTimeout(() => {
      this.ctx = this.canvas.getContext("2d");
      var myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          datasets: [
            {
              label: "Actual Data",
              data: this.data,
              fill: false,
              borderColor: "#3080d0",
            },
            {
              label: "Forecast",
              data: this.auto_regressor_india_data,
              fill: false,
              borderColor: "#ff6384",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          animations: {
            x: {
              type: "number",
              easing: "linear",
              duration: delayBetweenPoints,
              from: NaN, // the point is initially skipped
              delay(ctx: any) {
                if (ctx.type !== "data" || ctx.xStarted) {
                  return 0;
                }
                ctx.xStarted = true;
                return ctx.index * delayBetweenPoints;
              },
            },
            y: {
              type: "number",
              easing: "linear",
              duration: delayBetweenPoints,
              from: this.previousY,
              delay(ctx: any) {
                if (ctx.type !== "data" || ctx.yStarted) {
                  return 0;
                }
                ctx.yStarted = true;
                return ctx.index * delayBetweenPoints;
              },
            },
          },
          interaction: {
            intersect: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            title: {
              text: "Chart.js Time Scale",
              display: false,
            },
          },
          scales: {
            xAxes: {
              type: "time",
              time: {
                tooltipFormat: "DD-MMM-yyyy",
                unit: "month",
                displayFormats: {
                  quarter: "MMM yyyy",
                },
              },
            },
            yAxes: <any>[
              {
                type: "number",
                suggestedMin: 30,
                suggestedMax: 50,
              },
            ],
          },
        },
      });
    }, 500);
  }
  // sarimax India
  sarimaxIndia() {
    setTimeout(() => {
      var datas = [];
      datas = this.data;
      const delayBetweenPoints = this.totalDuration / this.data.length;

      this.indiaContextSarimax = this.indiaCanvasSarimax.getContext("2d");
      var indiaInlineChartSarimax = new Chart(this.indiaContextSarimax, {
        type: "line",
        data: {
          datasets: [
            {
              label: "Actual Data",
              data: this.data,
              fill: false,
              borderColor: "#3080d0",
            },
            {
              label: "Forecast",
              data: this.sarimax_india_data,
              fill: false,
              borderColor: "#ff6384",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          animations: {
            x: {
              type: "number",
              easing: "linear",
              duration: delayBetweenPoints,
              from: NaN,
              delay(ctx: any) {
                if (ctx.type !== "data" || ctx.xStarted) {
                  return 0;
                }
                ctx.xStarted = true;
                return ctx.index * delayBetweenPoints;
              },
            },
            y: {
              type: "number",
              easing: "linear",
              duration: delayBetweenPoints,
              from: this.previousY,
              delay(ctx: any) {
                if (ctx.type !== "data" || ctx.yStarted) {
                  return 0;
                }
                ctx.yStarted = true;
                return ctx.index * delayBetweenPoints;
              },
            },
          },
          interaction: {
            intersect: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            title: {
              text: "Chart.js Time Scale",
              display: false,
            },
          },
          scales: {
            xAxes: {
              type: "time",
              time: {
                tooltipFormat: "DD-MMM-yyyy",
                unit: "month",
              },
            },
          },
        },
      });
    }, 0);
  }
  // LSTM India
  lstm_india() {
    setTimeout(() => {
      const delayBetweenPoints = this.totalDuration / this.data.length;
      this.indiaContextLSTM = this.indiaCanvasLSTM.getContext("2d");
      var indiaInlineChartLSTM = new Chart(this.indiaContextLSTM, {
        type: "line",
        data: {
          datasets: [
            {
              label: "Actual Data",
              data: this.data,
              fill: false,
              borderColor: "#3080d0",
            },
            {
              label: "Forecast",
              data: this.lstm_india_data,
              fill: false,
              borderColor: "#ff6384",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          animations: {
            x: {
              type: "number",
              easing: "linear",
              duration: delayBetweenPoints,
              from: NaN,
              delay(ctx: any) {
                if (ctx.type !== "data" || ctx.xStarted) {
                  return 0;
                }
                ctx.xStarted = true;
                return ctx.index * delayBetweenPoints;
              },
            },
            y: {
              type: "number",
              easing: "linear",
              duration: delayBetweenPoints,
              from: this.previousY,
              delay(ctx: any) {
                if (ctx.type !== "data" || ctx.yStarted) {
                  return 0;
                }
                ctx.yStarted = true;
                return ctx.index * delayBetweenPoints;
              },
            },
          },
          interaction: {
            intersect: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            title: {
              text: "Chart.js Time Scale",
              display: false,
            },
          },
          scales: {
            xAxes: {
              type: "time",
              time: {
                tooltipFormat: "DD-MMM-yyyy",
                unit: "month",
              },
            },
          },
        },
      });
    }, 0);
  }
  // Auto regressor Wolrd
  auto_regressor_world() {
    const delayBetweenPointsWolrd =
      this.totalDuration / this.worldOldData.length;

    this.ctxWorld = this.worldRegressorCanvas;
    var wordChart = new Chart(this.ctxWorld, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Actual Data",
            data: this.worldOldData,
            fill: false,
            borderColor: "#3080d0",
          },
          {
            label: "Forecast",
            data: this.auto_regressor_world_data,
            fill: false,
            borderColor: "#ff6384",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        animations: {
          x: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPointsWolrd,
            from: NaN,
            delay(ctx: any) {
              if (ctx.type !== "data" || ctx.xStarted) {
                return 0;
              }
              ctx.xStarted = true;
              return ctx.index * delayBetweenPointsWolrd;
            },
          },
          y: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPointsWolrd,
            from: this.previousY,
            delay(ctx: any) {
              if (ctx.type !== "data" || ctx.yStarted) {
                return 0;
              }
              ctx.yStarted = true;
              return ctx.index * delayBetweenPointsWolrd;
            },
          },
        },
        interaction: {
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          title: {
            text: "Chart.js Time Scale",
            display: false,
          },
        },
        scales: {
          xAxes: {
            type: "time",
            time: {
              tooltipFormat: "DD-MMM-yyyy",
              unit: "month",
            },
          },
        },
      },
    });
  }
  // Sarimax World
  sarimax_world() {
    const delayBetweenPointsWolrd =
      this.totalDuration / this.worldOldData.length;
    this.worldContextSarimax = this.worldCanvasSarimax;
    var wordChartSarimax = new Chart(this.worldContextSarimax, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Actual Data",
            data: this.worldOldData,
            fill: false,
            borderColor: "#3080d0",
          },
          {
            label: "Forecast",
            data: this.sarimax_world_data,
            fill: false,
            borderColor: "#ff6384",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        animations: {
          x: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPointsWolrd,
            from: NaN,
            delay(ctx: any) {
              if (ctx.type !== "data" || ctx.xStarted) {
                return 0;
              }
              ctx.xStarted = true;
              return ctx.index * delayBetweenPointsWolrd;
            },
          },
          y: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPointsWolrd,
            from: this.previousY,
            delay(ctx: any) {
              if (ctx.type !== "data" || ctx.yStarted) {
                return 0;
              }
              ctx.yStarted = true;
              return ctx.index * delayBetweenPointsWolrd;
            },
          },
        },
        interaction: {
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          title: {
            text: "Chart.js Time Scale",
            display: false,
          },
        },
        scales: {
          xAxes: {
            type: "time",
            time: {
              tooltipFormat: "DD-MMM-yyyy",
              unit: "month",
            },
          },
        },
      },
    });
  }

  // LSTM Wolrd
  lstm_wolrd() {
    const delayBetweenPointsWolrd =
      this.totalDuration / this.worldOldData.length;
    this.worldContextLSTM = this.worldCanvasLSTM;
    var wordChartLSTM = new Chart(this.worldContextLSTM, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Actual Data",
            data: this.worldOldData,
            fill: false,
            borderColor: "#3080d0",
          },
          {
            label: "Forecast",
            data: this.lstm_world_data,
            fill: false,
            borderColor: "#ff6384",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        animations: {
          x: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPointsWolrd,
            from: NaN,
            delay(ctx: any) {
              if (ctx.type !== "data" || ctx.xStarted) {
                return 0;
              }
              ctx.xStarted = true;
              return ctx.index * delayBetweenPointsWolrd;
            },
          },
          y: {
            type: "number",
            easing: "linear",
            duration: delayBetweenPointsWolrd,
            from: this.previousY,
            delay(ctx: any) {
              if (ctx.type !== "data" || ctx.yStarted) {
                return 0;
              }
              ctx.yStarted = true;
              return ctx.index * delayBetweenPointsWolrd;
            },
          },
        },
        interaction: {
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          title: {
            text: "Chart.js Time Scale",
            display: false,
          },
        },
        scales: {
          xAxes: {
            type: "time",
            time: {
              tooltipFormat: "DD-MMM-yyyy",
              unit: "month",
            },
          },
        },
      },
    });
  }

  // model performance
  modelperformance() {
    this.indiaPerformanceCanvas = document.getElementById(
      "indiaPerformace"
    ) as HTMLCanvasElement;
    this.indiaPerformanceContext = this.indiaPerformanceCanvas;
    var india_performanceChart = new Chart(this.indiaPerformanceContext, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Actual Data",
            data: this.modal_performation_india_original,
            fill: false,
            borderColor: "#3080d0",
          },
          {
            label: "Sarimax",
            data: this.modal_performation_india_sarimax,
            fill: false,
            borderColor: "green",
          },
          {
            label: "LSTM",
            data: this.modal_performation_india_LSTM,
            fill: false,
            borderColor: "purple",
          },
          {
            label: "AR",
            data: this.modal_performation_india_AR,
            fill: false,
            borderColor: "#ff6384",
          },
        ],
      },
      options: {
        animations: {
          radius: {
            duration: 1000,
            easing: "linear",
            loop: (context) => context.active,
          },
        },
        interaction: {
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          title: {
            text: "Chart.js Time Scale",
            display: false,
          },
        },
      },
    });

    this.worldPerformanceCanvas = document.getElementById(
      "worldPerformace"
    ) as HTMLCanvasElement;
    this.worldPerformanceContext = this.worldPerformanceCanvas;
    var world_performanceChart = new Chart(this.worldPerformanceContext, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Actual Data",
            data: this.modal_performation_world_original,
            fill: false,
            borderColor: "#3080d0",
          },
          {
            label: "Sarimax",
            data: this.modal_performation_world_sarimax,
            fill: false,
            borderColor: "green",
          },
          {
            label: "LSTM",
            data: this.modal_performation_world_LSTM,
            fill: false,
            borderColor: "purple",
          },
          {
            label: "AR",
            data: this.modal_performation_world_AR,
            fill: false,
            borderColor: "#ff6384",
          },
        ],
      },
      options: {
        animations: {
          radius: {
            duration: 1000,
            easing: "linear",
            loop: (context) => context.active,
          },
        },
        interaction: {
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          title: {
            text: "Chart.js Time Scale",
            display: false,
          },
        },
      },
    });
  }
  chatbotSubmit(data: any) {
    localStorage.setItem("chatbotQuestion", data.question);
    this.router.navigate(["/covid-analytics/chatbot"]);
  }
}
