import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  constructor(private http: HttpClient) {}

  getAnswers(data: any): Observable<any> {
    return this.http
      .post(`${environment.api_url}` + "/chatbot", { Question: data })
      .pipe(map((res) => res));
  }

  // getAnswers() {

  // }
}
