<!-- <app-header></app-header> -->
<div class="main-container">
  <div class="mt-5 row p-custom-3">
    <button
      class="btn btn-outline-primary btn-back header-btn col-3 col-md-1"
      routerLink="/covid-analytics/home"
    >
      <i class="arrow-left"></i>
      Back
    </button>

    <button
      class="
        btn btn-outline-secondary
        header-btn
        me-sm-1 me-md-3
        ms-auto
        col-4 col-md-2
      "
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      Forecast Covid-19
    </button>
    <button
      class="btn btn-outline-secondary header-btn col-4 col-md-2"
      routerLink="/covid-analytics/dashboard-analytics"
    >
      Covid-19 Dashboard
    </button>
  </div>
  <div class="p-custome-5 mt-4">
    <div class="py-2">
      <h2>Covid-19 Chatbot</h2>
    </div>
    <form [formGroup]="searchForm" (ngSubmit)="sendQuestion(searchForm.value)">
      <div class="main-content">
        <div class="d-flex justify-content-center">
          <div class="form__group">
            <input
              type="text"
              rows="1"
              id="message"
              class="form__field fs-5 pe-4"
              placeholder=" "
              [(ngModel)]="question"
              formControlName="questionfield"
            />
            <label for="message" class="form__label"> Question</label>
            <div class="clear-button-container">
              <button
                type="button"
                *ngIf="formControls.questionfield.value != ''"
                (click)="clearTextBox()"
                class="clear-button"
              >
                X
              </button>
            </div>
          </div>
          <div class="btn-container">
            <button
              type="submit"
              class="btn btn-success"
              [disabled]="formControls.questionfield.value == ''"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cursor-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="">
          <div class="p-md-4 p-sm-2">
            <div class="">
              <div class="p-md-4">
                <div
                  class="d-flex mt-2"
                  *ngIf="answersList == undefined || answersList == []"
                >
                  <div class="pl-0 pr-0 mt-1 text-center">
                    <div class="p-3">
                      <div>
                        <span>Sorry Data Not Found :(</span>
                      </div>
                      <div>
                        <p>
                          Please try again with valid keyword , Thank you :)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex my-5" *ngFor="let ans of answersList">
                  <img
                    class="profile-image"
                    src="/assets/images/bot-icon-25.jpg"
                  />
                  <div class="message-box pl-0 pr-0">
                    <div class="answer-index">
                      <span><b>Relevant Answer:</b> {{ ans.index }}</span>
                    </div>
                    <div class="p-3">
                      <p>
                        {{ ans.answer }}
                      </p>
                    </div>
                    <div
                      class="relevance-container"
                      [ngClass]="{
                        'bg-green':
                          ans.Relevance <= '100%' || ans.Relevance >= '75%',
                        'bg-yellow':
                          ans.Relevance < '75%' && ans.Relevance >= '25%',
                        'bg-red': ans.Relevance < '25%' && ans.Relevance >= '0%'
                      }"
                    >
                      <span class="px-1 fs-12">
                        <b>Relevance: </b>{{ ans.Relevance }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-center" *ngIf="answersList.length != 0">
                  <span
                    ><b
                      >Data Courtesy:
                      <a
                        class="overflow-wrap-break"
                        target="_blank"
                        href="https://www.kaggle.com/xhlulu/covidqa"
                      >
                        https://www.kaggle.com/xhlulu/covidqa
                      </a></b
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- start modal  -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <form
    [formGroup]="forecastForm"
    (ngSubmit)="forecastSubmit(forecastForm.value)"
  >
    <div class="modal-dialog col-md-12">
      <div class="modal-content rounded-lg">
        <div class="modal-header">
          <h5 class="modal-title fs-14" id="exampleModalLabel">
            Forecasting of the covid cases for India and worldwide
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body fs-12">
          <span for="forecastNo">Forecast number of day </span>
          <input
            class="text px-1 py-1 w-25 ms-3"
            id="forecastNo"
            type="number"
            formControlName="number"
            placeholder="Enter number"
            max="15"
            min="1"
          />
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            data-bs-dismiss="modal"
            class="btn btn-primary fs-14"
          >
            Submit
          </button>
          <button
            type="button"
            class="btn btn-secondary fs-14"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
